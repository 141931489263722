$main: #ffab97;
:root {
  --primary-color: #ffab97;
  --white: white;
  --black: #000000;
  --dark: rgba(0, 0, 0, 0.78);
  --light-dark: #262626;
  --grey: rgba(0, 0, 0, 0);
}

$main-padding: 160px;

$sm: "only screen and (min-width : 320px) and (max-width: 768px)";
$md: "only screen and (min-width : 769px) and (max-width: 1280px)";
$lg: "only screen and (min-width : 1280px)";

// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more —  Extra large screens, TV

$mobile: "only screen and (min-width : 320px) and (max-width: 480px)";
$tabs: "only screen and (min-width : 481px) and (max-width: 768px)";
$slarge: "only screen and (min-width : 769px) and (max-width: 1024px)";
$large: "only screen and (min-width : 1025px) and (max-width: 1200px)";
$xlarge: "only screen and (min-width : 1201px)";
