@import "./variables.scss";

.grid {
  display: grid;
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-col-rev {
  flex-direction: column-reverse;
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.self-center {
  align-self: center;
}
.select-none {
  user-select: none;
}
.rounded-full {
  border-radius: 50%;
}
.rounded-10 {
  border-radius: 10px;
}
.rounded-4 {
  border-radius: 4px;
}
.display-none {
  display: none;
}
.display-inline {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
.h-full {
  height: 100%;
}
.h-50 {
  height: 50%;
}
.w-full {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.select-none {
  user-select: none;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-white {
  color: #fff;
}
.text-italic {
  font-style: italic;
}
.bg-white {
  background-color: #fff;
}
.word-break {
  word-break: break-word;
}
.outline-none {
  outline: none;
}
.border-none {
  border: none;
}
.overflow-hidden {
  overflow: hidden;
}
.text-white {
  color: #fff;
}
.bg-white {
  color: #fff;
}
.bg-cover {
  background-size: cover;
}
.text-main {
  color: $main !important;
}
.link-underline {
  text-decoration: underline !important;
  text-decoration-color: white !important;
}
.opacity-50 {
  opacity: 0.5;
}
.bg-main {
  background-color: $main;
}
.apercu-mono {
  font-family: "Apercu-Mono" !important;
}
.underline {
  text-decoration: underline;
}
.border-grey {
  border-color: #ffffff4d !important;
}
.d-block {
  display: block;
}
.content-holder {
  max-width: 1200px;
  margin: 0 auto;
}
.p-root {
  padding-left: $main-padding;
  padding-right: $main-padding;
  @media #{$sm} {
    padding-left: 24px;
    padding-right: 24px;
  }
}
a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.font-tagline {
  font-size: 51px;
  letter-spacing: -2.8px;
  overflow: hidden;
  transition: all 1s;
  width: 100%;
  @media #{$sm} {
    line-height: 1.11;
    font-size: 48px;
  }
}
.hero-tagline {
  font-size: 50px;
  @media #{$sm} {
    font-size: 36px;
  }
}
// generates text-12 class for font-sizes
@for $i from 10 through 40 {
  .text-#{$i} {
    font-size: 1px * $i;
  }
}

// font-300 for font-weight: 300
@for $i from 1 through 9 {
  .font-#{$i*100} {
    font-weight: 100 * $i;
  }
}

//padding-left
@for $i from 1 through 30 {
  .pl-#{$i*2} {
    padding-left: 2px * $i;
  }
}
//padding-right
@for $i from 1 through 30 {
  .pr-#{$i*2} {
    padding-right: 2px * $i;
  }
}
//padding-horizontsl
@for $i from 1 through 30 {
  .px-#{$i*2} {
    padding-left: 2px * $i;
    padding-right: 2px * $i;
  }
}

//padding-top
@for $i from 1 through 30 {
  .pt-#{$i*2} {
    padding-top: 2px * $i;
  }
}
//padding-bottom
@for $i from 1 through 30 {
  .pb-#{$i*2} {
    padding-bottom: 2px * $i;
  }
}
//padding-vertical
@for $i from 1 through 30 {
  .py-#{$i*2} {
    padding-top: 2px * $i;
    padding-bottom: 2px * $i;
  }
}

// margin-bottom
@for $i from 1 through 20 {
  .mb-#{$i*2} {
    margin-bottom: 2px * $i;
  }
}

//margin-left
@for $i from 1 through 20 {
  .ml-#{$i*2} {
    margin-left: 2px * $i;
  }
}
//margin-vertical
@for $i from 1 through 50 {
  .my-#{$i*2} {
    margin-top: 2px * $i;
    margin-bottom: 2px * $i;
  }
}

// margin-right
@for $i from 1 through 20 {
  .mr-#{$i*2} {
    margin-right: 2px * $i;
  }
}

// margin-top
@for $i from 1 through 100 {
  .mt-#{$i*2} {
    margin-top: 2px * $i;
  }
}

// opacity
@for $i from 1 through 10 {
  .opacity-#{$i} {
    opacity: $i/10;
  }
}

// Primary button
.btn-primary {
  background: var(--white);
  color: var(--black);
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  border: unset;
}
.btn-lg {
  padding: 15px 20px;
  font-size: 14px;
}
.hide {
  visibility: hidden;
}
