// @font-face {
//   font-family: "Apercu";
//   src: url('./Gilroy-Thin.otf') format('truetype');
//   font-style: normal;
//   font-weight: 100;
// }
// @font-face {
//   font-family: "Apercu";
//   src: url('./Gilroy-UltraLight.otf') format('truetype');
//   font-style: normal;
//   font-weight: 200;
// }
@font-face {
  font-family: "Apercu-Mono";
  src: url('./Apercu-Mono.otf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: "Apercu";
  src: url('./Apercu-Light.otf') format('truetype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Apercu";
  src: url('./Apercu-Regular.otf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Apercu";
  src: url('./Apercu-Medium.otf') format('truetype');
  font-style: normal;
  font-weight: 500;
}
// @font-face {
//   font-family: "Apercu";
//   src: url('./Apercu-SemiBold.otf') format('truetype');
//   font-style: normal;
//   font-weight: 600;
// }
// @font-face {
//   font-family: "Apercu";
//   src: url('./Apercu-Bold.otf') format('truetype');
//   font-style: normal;
//   font-weight: 700;
// }
// @font-face {
//   font-family: "Apercu";
//   src: url('./Apercu-ExtraBold.otf') format('truetype');
//   font-style: normal;
//   font-weight: 800;
// }
// @font-face {
//   font-family: "Apercu";
//   src: url('./Apercu-Black.otf') format('truetype');
//   font-style: normal;
//   font-weight: 900;
// }