@import "../assets/stylesheets/variables.scss";

.splash {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  img {
    width: 20%;
    @media #{$mobile} {
      width: 60%;
    }
  }
}
