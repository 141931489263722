@import "./assets/stylesheets/variables.scss";
body {
  margin: 0;
  font-family: "Apercu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #fff;
}

* {
  box-sizing: border-box;
}

.show-btn {
  transform: translate(0);
  opacity: 1;
}

.hide-btn {
  transform: translateY(100px);
  opacity: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link {
  text-decoration: none;
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  padding-bottom: 2px;
  border-bottom: 1px solid #000;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #fff;
  }
}
.nav-link-active {
  .nav-link {
    color: #{$main};
    &:hover {
      border-bottom: 1px solid $main;
    }
  }
}

.article {
  padding: 80px;
  background-origin: content-box;
  width: 50%;
  min-height: 300px;
  @media #{$sm} {
    width: 100%;
    border-right: none !important;
    padding-left: 20px !important;
  }
  &:hover {
    background-image: url("./assets/images/article-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
  }
}
.partners-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.partners {
  // max-width: 1px;
  width: 100%;
  border-radius: 3px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;
  overflow: hidden;

  @media #{only screen and (min-width : 875px) and (max-width: 1080px)} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media #{only screen and (min-width : 769px) and (max-width: 875px)} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{$sm} {
    grid-template-columns: 1fr;
  }

  .partner {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 150px;
    height: 150px;
    img {
      object-fit: contain;
      max-width: 120px;
    }
  }
}

#partner-9 {
  img {
    max-width: 150px;
  }
}

.w-40 {
  width: 40px;
}
.h-40 {
  height: 40px;
}
.footer-container {
  height: 500px;
  margin-top: 150px;
  padding-bottom: 30px;
  @media #{$sm} {
    height: 300px;
    margin-top: 100px;
    padding-bottom: 90px;
    text-align: center;
    .tag-line {
      font-size: 17px;
    }
  }
}
.copyright-texts {
  @media #{$sm} {
    flex-direction: column;
  }
}
.copyright-texts + .copyright-content {
  @media #{$sm} {
    flex-direction: column-reverse;
  }
}

.b-border {
  border-bottom: 1px solid #ffffff4d;
}

.footer-logo {
  width: 330px;
  height: 125px;
  @media #{$sm} {
    width: 40vw;
    max-width: 300px;
  }
}

.sm-hide {
  display: block;
  @media #{$sm} {
    display: none !important;
  }
}

.sm-show {
  @media #{$sm} {
    display: block !important;
  }
}

.mobile-img {
  height: 700px;
  position: absolute;
  right: $main-padding;
}

.hero-container {
  padding-top: 110px;
  font-size: 50px;
  min-height: 300px;
  align-items: flex-end;
  padding-bottom: 40px;
}
@media #{$sm} {
  .hero-container {
    padding-top: 0px;
    min-height: 150px;
    padding-bottom: 15px;
  }
}
